var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.drawerDialog && !_vm.$vuetify.breakpoint.lgAndUp),expression:"drawerDialog && !$vuetify.breakpoint.lgAndUp"}],staticClass:"mobile-drawer position-fixed fill-width fill-height algo-grey darken-2",staticStyle:{"z-index":"100"}},[_c('v-container',[(_vm.wallet.account)?_c('AccountDetail',{staticClass:"mb-4",attrs:{"drawer":_vm.drawerDialog},on:{"update:drawer":function($event){_vm.drawerDialog=$event}}}):_vm._e(),_c('ConnectWallet',{attrs:{"drawer":_vm.drawerDialog},on:{"update:drawer":function($event){_vm.drawerDialog=$event}}}),_c('div',{staticClass:"text-center mt-8"},[_c('div',{staticClass:"mb-8"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-decoration-none",class:_vm.path === '/summary' ? 'primary--text' : 'white--text'},'div',attrs,false),on),[_vm._v(" DASHBOARD ")])]}}])},[_c('span',[_vm._v("Coming soon")])])],1),_c('div',{staticClass:"mb-8"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-decoration-none",class:_vm.path.includes('projects') ? 'primary--text' : 'white--text'},'div',attrs,false),on),[_vm._v(" PROJECT ")])]}}])},[_c('span',[_vm._v("Coming soon")])])],1),_c('div',{staticClass:"mb-8"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-decoration-none",class:_vm.path.includes('allocation') ? 'primary--text' : 'white--text'},'div',attrs,false),on),[_vm._v(" FORUM ")])]}}])},[_c('span',[_vm._v("Coming soon")])])],1),_c('div',{staticClass:"mb-8"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-decoration-none",class:_vm.path.includes('allocation') ? 'primary--text' : 'white--text'},'div',attrs,false),on),[_vm._v(" MARKET ")])]}}])},[_c('span',[_vm._v("Coming soon")])])],1),_c('div',{staticClass:"mb-8"},[_c('router-link',{staticClass:"text-decoration-none",class:_vm.path === '/vault' ? 'primary--text' : 'white--text',attrs:{"to":"/vault"}},[_vm._v(" VAULT ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }