



















































































































import { walletStore } from '@/stores/wallet-store'
import { Component, PropSync, Vue } from 'vue-property-decorator'

@Component({
  components: {
    AccountDetail: () => import('./account-detail.vue'),
    ConnectWallet: () => import('./connect-wallet.vue'),
  },
})
export default class Drawer extends Vue {
  @PropSync('drawer') drawerDialog!: boolean

  wallet = walletStore

  get path() {
    return !this.$route.matched.length ? '' : this.$route.matched[0].path
  }
}
